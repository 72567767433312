// if installed via yarn, but then we can't modify sources
// import 'vanilla-cookieconsent/src/cookieconsent';
// import 'vanilla-cookieconsent/dist/cookieconsent.css';
// downloaded to local folder
import "../libs/dist/cookieconsent.prod";
import "../libs/dist/cookieconsent.min.css";
import "../styles/shared/front/cookie_consent.scss";

// example when analytics available
// document.addEventListener('cc:analytics', event => {
//     console.log('analytics available')
// });
window.addEventListener('load', function () {
    window.cc = initCookieConsent();
    window.cc.run({
        auto_language: 'document',
        page_scripts: true,
        onAccept: function (cookie) {
            cookie.level.forEach(function(type) {
                let myEvent = new Event(`cc:${type}`, {
                    bubbles: true,
                    cancelable: true,
                    composed: false,
                });
                document.dispatchEvent(myEvent);
            })
        },
        languages: {
            'en': {
                consent_modal: {
                    title: 'This site uses cookies!',
                    description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking & analytics cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                    primary_btn: {
                        text: 'Accept all',
                        role: 'accept_all'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Configure',
                        role: 'settings'        // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Cookie preferences',
                    save_settings_btn: 'Save settings',
                    accept_all_btn: 'Accept all',
                    reject_all_btn: 'Reject all',
                    close_btn_label: 'Close',
                    blocks: [
                        {
                            title: 'Cookie usage 📢',
                            description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. <br>For more details relative to cookies and other sensitive data, please read the full <a href="/en/privacy-policy" class="cc-link">privacy policy</a>.'
                        }, {
                            title: 'Strictly necessary cookies',
                            description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                            }
                        }, {
                            title: 'Performance and Functionality cookies',
                            description: 'These cookies are used to provide you with a more personalized experience on our website and to remember choices you make when you use our website.',
                            toggle: {
                                value: 'functional',     // your cookie category
                                enabled: false,
                                readonly: false
                            }
                        }, {
                            title: 'Analytics cookies',
                            description: 'These cookies are used to collect information to analyze the traffic to our website and how visitors are using our website.<br>For example, these cookies may track things such as how long you spend on the website or the pages you visit which helps us to understand how we can improve our website site for you.',
                            toggle: {
                                value: 'analytics',     // your cookie category
                                enabled: false,
                                readonly: false
                            }
                        // }, {
                        //     title: 'Advertisement and Targeting cookies',
                        //     description: "These cookies are used to show advertising that is likely to be of interest to you based on your browsing habits. <br>These cookies, as served by our content and/or advertising providers, may combine information they collected from our website with other information they have independently collected relating to your web browser's activities across their network of websites.",
                        //     toggle: {
                        //         value: 'targeting',
                        //         enabled: false,
                        //         readonly: false
                        //     }
                        // }, {
                        //     title: 'More information',
                        //     description: 'For more details relative to cookies and other sensitive data, please read the full <a href="/en/privacy-policy" class="cc-link">privacy policy</a>.',
                        }
                    ]
                }
            },
            'es': {
                consent_modal: {
                    title: 'Este sitio usa cookies!',
                    description: 'Hola!, este sitio utiliza cookies necesarias para el funcionamiento del sitio y otras para seguimiento y analítica para entender cómo interactúas con el sitio. Las últimas sólo se utilizarán si das consentimiento para ello. <button type="button" data-cc="c-settings" class="cc-link">Elegir</button>',
                    primary_btn: {
                        text: 'Acceptar todas',
                        role: 'accept_all'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Configurar',
                        role: 'settings'        // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Preferencias de Cookies',
                    save_settings_btn: 'Guardar seleccionadas',
                    accept_all_btn: 'Acceptar todas',
                    reject_all_btn: 'Rechazar todas',
                    close_btn_label: 'Cerrar',
                    blocks: [
                        {
                            title: 'Uso de cookies 📢',
                            description: 'Las cookies son pequeños archivos de texto que se almacenan en tu navegador cuando visitas nuestra web. Utilizamos cookies para diferentes objetivos y para mejorar tu experiencia en nuestro sitio web (por ejemplo, para recordar tus detalles de acceso). <br>Para más información relativa a cookies o privacidad visita nuestra <a href="/politica-de-privacidad" class="cc-link">política de privacidad</a>.'
                        }, {
                            title: 'Cookies estrictamente necesarias',
                            description: 'Estos cookies son esenciales para proveerte los servicios disponibles en nuestra web y para permitirte utilizar algunas características de nuestra web.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                            }
                        }, {
                            title: 'Cookies de funcionalidad',
                            description: 'Estas cookies son utilizadas para proveerte una experiencia más personalizada y recordar tus elecciones en nuestra web.',
                            toggle: {
                                value: 'functional',     // your cookie category
                                enabled: false,
                                readonly: false
                            }
                        }, {
                            title: 'Cookies de analítica y rendimiento',
                            description: 'Estas cookies son utilizadas para recopilar información, para analizar el tráfico y la forma en que los usuarios utilizan nuestra web. <br>Por ejemplo, estas cookies pueden recopilar datos como cuánto tiempo llevas navegado en nuestro sitio web o qué páginas visitas, cosa que nos ayuda a comprender cómo podemos mejorar nuestra web para ti.',
                            toggle: {
                                value: 'analytics',     // your cookie category
                                enabled: false,
                                readonly: false
                            }
                        // }, {
                        //     title: 'Cookies de seguimiento y publicidad',
                        //     description: "Estas cookies son utilizadas para enseñarte anuncios que pueden ser interesantes basados en tus costumbres de navegación. Estas cookies, servidas por nuestros proveedores de contenido y/o de publicidad, pueden combinar la información que ellos recogieron de nuestro sitio web con otra información recopilada por ellos en relación con las actividades de su navegador a través de su red de sitios web.",
                        //     toggle: {
                        //         value: 'targeting',
                        //         enabled: false,
                        //         readonly: false
                        //     }
                        // }, {
                        //     title: 'Más información',
                        //     description: 'Para más información relativa a cookies o privacidad visita nuestra <a href="/politica-de-privacidad" class="cc-link">política de privacidad</a>.',
                        }
                    ]
                }
            },
            'fr': {
                consent_modal: {
                    title: 'Ce site utilise des cookies !',
                    description: "Bonjour, ce site utilise des cookies nécessaires au fonctionnement du site et d'autres pour le suivi et l'analyse afin de comprendre comment vous interagissez avec le site. Ces dernières ne seront utilisées que si vous y consentez. <button type=\"button\" data-cc=\"c-settings\" class=\"cc-link\">Choisissez</button>",
                    primary_btn: {
                        text: 'Accepter tous',
                        role: 'accept_all'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Configurer',
                        role: 'settings'        // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Préférences de cookies',
                    save_settings_btn: 'Sauvegarder la sélection',
                    accept_all_btn: 'Accepter tous',
                    reject_all_btn: 'Rejeter tous',
                    close_btn_label: 'Fermer',
                    blocks: [
                        {
                            title: 'Utilisation des cookies 📢',
                            description: "Les cookies sont de très petits fichiers texte qui sont stockés sur votre ordinateur lorsque vous visitez un site Web. Nous utilisons des cookies à diverses fins et pour améliorer votre expérience en ligne sur notre site Web (par exemple, pour mémoriser les informations de connexion de votre compte). <br>Pour plus d'informations sur les cookies ou la confidentialité, veuillez consulter notre <a href=\"/fr/politique-de-confidentialite\" class=\"cc-link\">politique de confidentialité.</a>."                        }, {
                            title: 'Cookies strictement nécessaires',
                            description: 'Ces cookies sont essentiels pour vous fournir les services disponibles sur notre site Web et vous permettre d’utiliser certaines fonctionnalités de notre site Web.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                            }
                        }, {
                            title: 'Cookies de Fonctionnalité',
                            description: 'Ces cookies servent à vous offrir une expérience plus personnalisée sur notre site Web et à mémoriser les choix que vous faites lorsque vous utilisez notre site Web',
                            toggle: {
                                value: 'functional',     // your cookie category
                                enabled: false,
                                readonly: false
                            }
                        }, {
                            title: 'Cookies de suivi et de performance',
                            description: "Ces cookies sont utilisés pour collecter des informations permettant d'analyser le trafic sur notre site et la manière dont les visiteurs utilisent notre site. <br>Par exemple, ces cookies peuvent suivre des choses telles que le temps que vous passez sur le site Web ou les pages que vous visitez, ce qui nous aide à comprendre comment nous pouvons améliorer notre site Web pour vous.",
                            toggle: {
                                value: 'analytics',     // your cookie category
                                enabled: false,
                                readonly: false
                            }
                        // }, {
                        //     title: 'Cookies de ciblage et de publicité',
                        //     description: "Ces cookies sont utilisés pour afficher des publicités susceptibles de vous intéresser en fonction de vos habitudes de navigation. <br>Ces cookies, tels que servis par nos fournisseurs de contenu et / ou de publicité, peuvent associer des informations qu'ils ont collectées sur notre site Web à d'autres informations qu'ils ont collectées de manière indépendante et concernant les activités du votre navigateur Web sur son réseau de sites Web.",
                        //     toggle: {
                        //         value: 'targeting',
                        //         enabled: false,
                        //         readonly: false
                        //     }
                        // }, {
                        //     title: "Plus d'information",
                        //     description: "Pour plus d'informations sur les cookies ou la confidentialité, veuillez consulter notre <a href=\"/fr/politique-de-confidentialite\" class=\"cc-link\">politique de confidentialité.</a>.",
                        }
                    ]
                }
            },
            // 'ca': {
            //     consent_modal: {
            //         title: 'Aquest lloc utilitza cookies!',
            //         description: "Hola!, aquest lloc utilitza cookies necessàries per al funcionament del lloc i altres per a seguiment i analítica per a entendre com interactues amb el lloc. Les últimes només s'utilitzaran si dones consentiment per a això. <button type=\"button\" data-cc=\"c-settings\" class=\"cc-link\">Triar</button>",
            //         primary_btn: {
            //             text: 'Acceptar totes',
            //             role: 'accept_all'              // 'accept_selected' or 'accept_all'
            //         },
            //         secondary_btn: {
            //             text: 'Configurar',
            //             role: 'settings'        // 'settings' or 'accept_necessary'
            //         }
            //     },
            //     settings_modal: {
            //         title: 'Preferències de Cookies',
            //         save_settings_btn: 'Guardar seleccionades',
            //         accept_all_btn: 'Acceptar totes',
            //         reject_all_btn: 'Rebutjar totes',
            //         close_btn_label: 'Tancar',
            //         blocks: [
            //             {
            //                 title: 'Ús de cookies 📢',
            //                 description: "Les cookies són xicotets arxius de text que s'emmagatzemen en el teu navegador quan visites la nostra web. Utilitzem cookies per a diferents objectius i per a millorar la teua experiència en el nostre lloc web (per exemple, per a recordar els teus detalls d'accés). <br>Per a més informació relativa a cookies o privacitat visita la nostra <a href=\"/ca/politica-privacitat\" class=\"cc-link\">política de cookies.</a>."
            //             }, {
            //                 title: 'Cookies estrictament necessàries',
            //                 description: 'Aquestes cookies són essencials per oferir-vos el nostres serveis i funcionalitats al nostre lloc web.',
            //                 toggle: {
            //                     value: 'necessary',
            //                     enabled: true,
            //                     readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
            //                 }
            //             }, {
            //                 title: 'Cookies de funcionalitat',
            //                 description: 'Aquestes cookies ens permeten oferir-vos una experiència personalitzada i recordar la vostra configuració quan feu servir el nostre lloc web',
            //                 toggle: {
            //                     value: 'functional',     // your cookie category
            //                     enabled: false,
            //                     readonly: false
            //                 }
            //             }, {
            //                 title: "Cookies d'analítica i rendiment",
            //                 description: "Aquestes cookies es fan servir per recollir informació, analitzar el tràfic i veure com es fa servir el nostre lloc web. <br>Per exemple, aquestes cookies podrien fer el seguiment de quant de temps visiteu el nostre web o quines pàgines visiteu les quals ens poden ajudar a entendre com millorar el lloc web per vosaltres.",
            //                 toggle: {
            //                     value: 'analytics',     // your cookie category
            //                     enabled: false,
            //                     readonly: false
            //                 }
            //             // }, {
            //             //     title: 'Cookies de publicitat i focalització',
            //             //     description: "Aquestes cookies es fan servir per mostrar anuncis que poden ser del vostre interès basats en els vostres hàbits d’us. <br>Aquestes cookies, servides tal i com ho fan els nostres proveïdors de publicitat i contingut, poden combinar informació recollida al nostre lloc web amb altra informació que hagin recollit independentment relacionada amb activitat a la seva xarxa de llocs web.",
            //             //     toggle: {
            //             //         value: 'targeting',
            //             //         enabled: false,
            //             //         readonly: false
            //             //     }
            //             // }, {
            //             //     title: "Més informació",
            //             //     description: "Per a més informació relativa a cookies o privacitat visita la nostra <a href=\"/ca/politica-privacitat\" class=\"cc-link\">política de cookies.</a>",
            //             }
            //         ]
            //     }
            // },
            'de': {
                consent_modal: {
                    title: 'Diese Website verwendet Cookies!',
                    description: "Hallo, diese Website verwendet essentielle Cookies, um ihren ordnungsgemäßen Betrieb zu gewährleisten, sowie Tracking- und Analyse-Cookies, um zu verstehen, wie Sie mit ihr interagieren. Letztere werden nur nach Zustimmung gesetzt. <button type=\"button\" data-cc=\"c-settings\" class=\"cc-link\">Lass mich wählen</button>",
                    primary_btn: {
                        text: 'Alle akzeptieren',
                        role: 'accept_all'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Konfigurieren',
                        role: 'settings'        // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Cookie-Einstellungen',
                    save_settings_btn: 'Einstellungen speichern',
                    accept_all_btn: 'Alle akzeptieren',
                    reject_all_btn: 'Alle ablehnen',
                    close_btn_label: 'Schließen',
                    blocks: [
                        {
                            title: 'Ihre Privatsphäre ist uns wichtig 📢',
                            description: "Cookies sind sehr kleine Textdateien, die auf Ihrem Rechner gespeichert werden, wenn Sie eine Website besuchen. <br>Wir verwenden Cookies für eine Reihe von Auswertungen, um damit Ihren Besuch auf unserer Website kontinuierlich verbessern zu können (z. B. damit Ihnen Ihre Login-Daten erhalten bleiben). Für weitere Einzelheiten zu Cookies und anderen sensiblen Daten lesen Sie bitte die vollständige <a href=\"/de/datenschutzbestimmungen\" class=\"cc-link\">Cookie-Richtlinie.</a>."
                        }, {
                            title: 'Technisch notwendige Cookies',
                            description: 'Diese Cookies sind für die Bereitstellung von Diensten, die über unsere Website verfügbar sind, und für die Verwendung bestimmter Funktionen unserer Website von wesentlicher Bedeutung.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                            }
                        }, {
                            title: 'Funktions Cookies',
                            description: 'Diese Cookies werden verwendet, um Ihnen ein persönlicheres Erlebnis auf unserer Website zu ermöglichen und um sich an Ihre Entscheidungen zu erinnern, die Sie bei der Nutzung unserer Website getroffen haben.',
                            toggle: {
                                value: 'functional',     // your cookie category
                                enabled: false,
                                readonly: false
                            }
                        }, {
                            title: 'Tracking und Performance Cookies',
                            description: "Diese Cookies werden zum Sammeln von Informationen verwendet, um den Verkehr auf unserer Website und die Nutzung unserer Website durch Besucher zu analysieren. <br>Diese Cookies können beispielsweise nachverfolgen, wie lange Sie auf der Website verweilen oder welche Seiten Sie besuchen. So können wir verstehen, wie wir unsere Website für Sie verbessern können.",
                            toggle: {
                                value: 'analytics',     // your cookie category
                                enabled: false,
                                readonly: false
                            }
                        // }, {
                        //     title: 'Targeting und Werbung Cookies',
                        //     description: "Diese Cookies werden genutzt, um Werbung anzuzeigen, die Sie aufgrund Ihrer Surfgewohnheiten wahrscheinlich interessieren wird. Diese Cookies, die von unseren Inhalten und / oder Werbeanbietern bereitgestellt werden, können Informationen, die sie von unserer Website gesammelt haben, mit anderen Informationen kombinieren, welche sie durch Aktivitäten Ihres Webbrowsers in Ihrem Netzwerk von Websites gesammelt haben.",
                        //     toggle: {
                        //         value: 'targeting',
                        //         enabled: false,
                        //         readonly: false
                        //     }
                        // }, {
                        //     title: "Mehr Informationen",
                        //     description: 'Für weitere Einzelheiten zu Cookies und anderen sensiblen Daten lesen Sie bitte die vollständige <a href=\"/de/datenschutzbestimmungen\" class=\"cc-link\">Cookie-Richtlinie.</a>.',
                        }
                    ]
                }
            }

        }
    });

});
